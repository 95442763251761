import {  post,get } from '../axiosconfig/axiostool'
//import 需要哪些引入哪些，我这里只需要post
export default {
  //天气
getWeather(param){
    return post('/tea-api/api/tea/dataTrend/getWeather',param);
},
//新闻
newsList(param){
    return post('/tea-api/api/tea/news/list',param);
  },
//茶叶数据
getTrendData(param){
  return post('/tea-api/api/tea/dataTrend/getTrendData',param);
},
//获取视频
queryVideo(param){
  return post('/tea-api/tea/video/queryVideo',param);
},
//环境数据
environmentInfo(param){
  return get('/tea-api/api/tea/terminal/environmentInfo',param);
},
//虫情分析
insectInfo(param){
  return get('/tea-api/api/tea/terminal/insectInfo',param);
},
//苗木情况
plantInfo(param){
  return get('/tea-api/api/tea/terminal/plantInfoNew',param);
},
//农事信息
getFarmInfo(param){
  return post('/tea-api/tea/video/getFarmInfo',param);
},
// 
getLiveUrl(param){
  
  
  return post('/tea-api/api/dahua/video/getLiveUrl?device='+param.deviceNumber,{});
},
}