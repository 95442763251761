import Vue from "vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './utils/rem'
import echarts from 'echarts'
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts 
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
// 统一接口地址 访问地址
import api from './service/apiconfig/'
import 'video.js/dist/video-js.css'
Vue.prototype.$api=api
//console.log(echarts)
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer,{
  zIndex: 9999,
  previewSize:6,
  
  
}) 
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
