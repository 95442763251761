import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    timeStrYear:'',
    
  },
  mutations: {
    changeTimeStrYear(state,timeStr){
      state.timeStr=timeStr
    }
  },
  actions: {},
  modules: {},
});
